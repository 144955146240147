import React, { useState } from 'react';
import './ProducActionCardBody.scss';
import AnimateHeight from 'react-animate-height';
import { parseObjectToDOM } from '../../../../../../../utils/timelineItem';
import axios from 'axios';
import { showError } from '../../../../../../../actions/actions';
import { InputSpinner } from '../../../../../../../components/Spinners/Spinners';
import translate from '../../../../../../../utils/translate';
import { useSelector } from 'react-redux';

const commissioning_action_type = "CommissioningAction";
const polandDepartments = ["AICSA", "AICEU", "AICPL"];
const ok_status = "product_status_ok";

const ProductActionCardBody = ( { height, action } ) => {
  const boiler_department = useSelector(state => state.productInfo.boiler.supporting_department_code);
  const boiler_status = useSelector(state => state.productInfo.boiler.status.key);
  const [loading, setLoading] = useState(false);
  
  const handleDownload = () => {
    setLoading(true);
    axios.get(`/api/product/commissioning_report/${action.id}`, {
      responseType: 'blob',
    })
      .then(( response ) => {
        setLoading(false);
        const file = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(file);
        saveAs(url);
      })
      .catch(( err ) => {
        showError(err);
        setLoading(false);
      });
  };

  const saveAs = ( url ) => {
    const link = document.createElement('a');
    link.href = url;
    link.rel = 'noopener noreferrer';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const shouldShowReportButton =
    action.type === commissioning_action_type &&
    polandDepartments.includes(boiler_department) &&
    boiler_status === ok_status;

  return (
    <AnimateHeight
    id='example-panel'
    duration={1000}
    height={height}
  >
    <hr style={{ margin: '0 24px' }} />
    <div className='product-history-timeline-card-details'>
      {parseObjectToDOM(action)}
      {shouldShowReportButton &&
        <div className="history-action history-action--button-container">
            <button
              className='btn btn-dark w-25'
              onClick={handleDownload}
              disabled={loading}
            >
              {loading ?
              <InputSpinner />
              : translate('Get commissioning report')}
            </button>
        </div>}
    </div>
    </AnimateHeight>);
};

export default ProductActionCardBody;
