import React, { useState } from 'react';
import './ProductInfo.scss';
import DeviceInfo from './DeviceInfo/DeviceInfo';
import DeviceDocuments from './DeviceDocuments/DeviceDocuments';
import PartsOverview from './PartsOverview/PartsOverview';
import { useLoadingWhileUpdateBoilerInfo } from '../ProductDashboard';
import LiveData from './LiveData/LiveData';
import SparepartsForProductInfo from './SparepartsForProductInfo/SparepartsForProductInfo';
import ProductOptions from './ProductOptions/ProductOptions';

const ProductInfo = (props) => {
  const [deviceInfo, setDeviceInfo] = useState(undefined);

  useLoadingWhileUpdateBoilerInfo(setDeviceInfo);

  return (
    <div className="product-info-wrapper">
      <div
        className="product-info-card"
        data-cy="device-info">
        <DeviceInfo deviceInfo={deviceInfo} />
      </div>
      <div className="product-info-card">
        <LiveData
          deviceInfo={deviceInfo}
          serial_number={props.serial_number}
        />
      </div>
      <div
        className="product-info-card"
        data-cy="device-docs">
        <DeviceDocuments deviceInfo={deviceInfo} />
      </div>
      <div className="product-info-card">
        <PartsOverview />
      </div>
      <div
        className="product-info-card"
        data-cy="device-find-spareparts">
        <SparepartsForProductInfo deviceInfo={deviceInfo} />
      </div>
      <div className="product-info-card">
        <ProductOptions deviceInfo={deviceInfo} />
      </div>
    </div>
  );
};

export default ProductInfo;
