import React from 'react';
import './ProductOptions.scss';
import translate from '../../../../../utils/translate';
import { Spinner2 } from '../../../../../components/Spinners/Spinners';

const ProductOptions = ({ deviceInfo }) => {
  if (!deviceInfo) {
    return (
      <>
        <div className="product-info-card-header">
          <p>{translate('Product Options')}</p>
          <Spinner2 />
        </div>
      </>
    );
  }

  if (!deviceInfo?.options?.length) {
    return null;
  }

  return (
    <>
      <div className="product-info-card-header">
        <p>{translate('Product Options')}</p>
      </div>
      <div className="product-info-options">
        {deviceInfo.options.map((option) => (
          <div key={option.option} className="option-line">
            <span className="option-number">{option.option}</span>
            {' - '}
            {option.name}
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductOptions; 